<template>
  <div>
    <h4>
      {{ $t('PROFILE.CERTIFICATIONS.my_certifications') }}
    </h4>
    <p class="paragraph">{{ $t('PROFILE.CERTIFICATIONS.my_certifications_desc') }}</p>
    <Certificates :items="userCertificates" />

    <h4 class="mt-5">
      {{ $t('PROFILE.CERTIFICATIONS.available_certificates') }}
    </h4>

    <p class="paragraph">{{ $t('PROFILE.CERTIFICATIONS.available_certificates_desc') }}</p>
    <Certificates :items="availableCertificates" pending />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Certificates from '@/components/profile/Certificates'

export default {
  name: 'ProfileCertifications',
  components: { Certificates },
  data () {
    return { }
  },
  methods: {
    ...mapActions({
      getAvailableCertificates: 'user/getAvailableCertificates'
    })
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      certificates: 'user/getAvailableCertificates'
    }),
    userCertificates () {
      return this.user.certifications
    },
    availableCertificates () {
      var userCertsId = this.userCertificates.map(i => i.id)
      return this.certificates.filter(item => userCertsId.indexOf(item.id) === -1)
    }
  },
  mounted () {
    this.getAvailableCertificates()
  }
}
</script>
