<template>
  <Card
    :img="img"
    no-shadow
    class="certification"
    :class="{ pending: pending }"
    >

    <template v-slot:title>
      <div >
        <div class="text-center" style="padding: 1rem;">
          <h4>{{ title }}</h4>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>

export default {
  name: 'CertificationCard',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    img: {
      type: String,
      required: false,
      default: ''
    },
    to: {
      type: Object,
      required: false,
      default: () => {}
    },
    pending: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    btnText () {
      return this.$t(`ACTIONS.${this.progress > 0 ? 'continue' : 'start'}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    .card-title-wrapper {
      width: 75%;
      display: inline-block;
      vertical-align: middle;
    }

    .progress-container {
      display: inline-block;
      width: 25%;
      vertical-align: middle;
      margin-left: auto;
      text-align: right;
    }
    .card-footer {
      border-top: none;
    }
  }
</style>
