<template>
  <div>
    <loading :loading="isLoading"></loading>

    <b-row class="w-100">
      <b-col
       class="mb-3" sm="12" md="6" lg="4" xl="3"
       v-for="cert in translatedItems" v-bind:key="cert.id">
        <CertificationCard :title="cert.name" :img="getUrlImg(cert.image)" :pending="pending" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18nmixin from '@/services/mixins/i18n.mixins'
import ToastsMixin from '@/services/mixins/toasts.mixins'
import MapperMixin from '@/services/mixins/mapper.mixins'

import CertificationCard from '@/components/utils/cards/CertificationCard'

export default {
  name: 'certificates',
  components: { CertificationCard },
  mixins: [i18nmixin, ToastsMixin, MapperMixin],
  props: {
    items: { required: true },
    pending: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      certificate: null
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'user/isLoading',
      user: 'auth/currentUser'
    }),
    translatedItems () { return this.translateObjectArray(this.items) }
  }
}
</script>

<style lang="scss" scoped>

</style>
